"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLeftRightArrowNavigation = void 0;
/* eslint-disable no-param-reassign */
var react_1 = require("react");
var scrollToWithCallback_1 = require("../utils/scrollToWithCallback");
var isAdJobGroup = function (element) { return element.classList.contains('job-group'); };
var additionalHeight = 160;
var useLeftRightArrowNavigation = function (ref, isNaturalScroll) {
    var _a = __read((0, react_1.useState)(0), 2), focusedElementIndex = _a[0], setFocusedElementIndex = _a[1];
    var focusedElementIndexRef = (0, react_1.useRef)(focusedElementIndex);
    var handleSetFocusedElementChange = function (index) {
        // console.log('handleSetFocusedElementChange', index);
        setFocusedElementIndex(index);
        focusedElementIndexRef.current = index;
    };
    // focusedElementIndexRef.current = focusedElementIndex;
    var isElementInViewport = function (el) {
        var rect = el.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    };
    (0, react_1.useEffect)(function () {
        var handleKeyDown = function (event) {
            var _a, _b, _c, _d;
            var keys = ['ArrowRight', 'ArrowLeft'];
            if (!keys.includes(event.key))
                return;
            // inputs-should-respond-to-keyboard-arrows is a class that should be added to all inputs that should respond to arrow keys
            var inputsComponents = ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.inputs-should-respond-to-keyboard-arrows:focus')) || [];
            if (inputsComponents.length > 0)
                return;
            // check if the media gallery modal is displayed, do not navigate
            var mediaGalleryModal = document.getElementById('media-gallery-modal');
            if (mediaGalleryModal)
                return;
            event.preventDefault();
            var focusableElements = ((_b = ref.current) === null || _b === void 0 ? void 0 : _b.querySelectorAll('.eva-keyboard-focusable')) || [];
            var nextIndex = focusedElementIndexRef.current + (event.key === 'ArrowRight' ? 1 : -1);
            var nextElement = focusableElements[nextIndex];
            if (nextElement) {
                focusableElements[focusedElementIndexRef.current].blur();
                if (!isElementInViewport(nextElement)) {
                    isNaturalScroll.current = false;
                    if (isAdJobGroup(nextElement)) {
                        // if the next focused item is an ad, go to the top of that ad
                        (0, scrollToWithCallback_1.scrollToWithCallback)(nextElement.offsetTop, 'smooth', function () {
                            isNaturalScroll.current = true;
                        });
                    }
                    if (!isAdJobGroup(nextElement)) {
                        // for the actions buttons, we need to scroll to the bottom of the job
                        var closetAddJobGroup = nextElement.closest('.job-group');
                        var bottomOfJob = ((_c = closetAddJobGroup === null || closetAddJobGroup === void 0 ? void 0 : closetAddJobGroup.offsetTop) !== null && _c !== void 0 ? _c : 0) +
                            ((_d = closetAddJobGroup === null || closetAddJobGroup === void 0 ? void 0 : closetAddJobGroup.offsetHeight) !== null && _d !== void 0 ? _d : 0) +
                            additionalHeight -
                            window.innerHeight;
                        (0, scrollToWithCallback_1.scrollToWithCallback)(bottomOfJob, 'smooth', function () {
                            isNaturalScroll.current = true;
                        });
                    }
                }
                nextElement.focus({ preventScroll: true });
                handleSetFocusedElementChange(nextIndex);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ref]);
    // as const makes TypeScript infer the array being returned as a readonly tuple. This will work perfectly.
    return [focusedElementIndex, handleSetFocusedElementChange];
};
exports.useLeftRightArrowNavigation = useLeftRightArrowNavigation;
