angular.module('eva2-angular')
  .service('ApiDictionaries', ['ajax', function(ajax) {
    'use strict';

    const ApiDictionaries = {
      get: function get(domain) {
        return ajax.get(`/sites/${domain}/filters/dictionaries?kind=mixed`)
          .then((data) => data.results);
      },
    };

    return ApiDictionaries;
  }]);
