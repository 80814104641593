import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@store';
import QueuePage from '@features/QueuePage';
import React from 'react';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('EvaJobsCtrl', EvaJobsCtrl);

  function EvaJobsCtrl($stateParams, $timeout, JobGroupManager, ApiService, EvaLogger, $state,
    CurrentUser, UserPermissionManager, $scope) {
    'ngInject';

    const vm = this;
    vm.domain = $stateParams.domain;
    vm.queueId = $stateParams.queueId || null; // default state is null
    const root = createRoot(document.querySelector('#queue-page'));


    vm.$onInit = () => {
      console.log('init');
      renderQueuePage();
    };

    $scope.$on('$destroy', function(){
      root.unmount();
      ApiService.unlockAds(vm.domain);
    });

    const renderQueuePage = () => {
      $timeout(() => {
        root.render(<Provider store={store}>
          <QueuePage
            domain={vm.domain}
            queueId={vm.queueId}
            angularJsDependencies={{
              EvaLogger,
              href: $state.href,
              CurrentUser,
              UserPermissionManager,
              submitDecision: JobGroupManager.submitDecision,
            }}
          />
        </Provider>);
      });
    };
  }
})();
