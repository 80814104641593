import { dataStoringApi } from '@common/services/dataStoring';
import { max, set } from 'date-fns';
import { ZERO_SECONDS } from '@utils/date/date';
import { maxFromDate, minDateFromPeriod } from '@features/ItemLogPage/util/fromDateUtil';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminFilterEditCtrl', AdminFilterEditCtrl);

  function AdminFilterEditCtrl(ApiFilters, $stateParams, $ngRedux, $state, Notifier, tFilter) {
    const vm = this;

    vm.save = save;
    vm.domain = $stateParams.domain;

    if (vm.domain) {
      $ngRedux.dispatch(
        dataStoringApi.endpoints.getDataStoringOption.initiate(vm.domain)
      ).then((response) => {
        vm.minDate = minDateFromPeriod(response.data.period).getTime();
      });
    }
    vm.cancel = function() {
      $state.go('app.assignment.automation.filterView', {
        domain: vm.domain,
        filterId: $stateParams.filterId,
        from: maxFromDate(vm.filter.lastModifiedTime, vm.minDate)
      });
    };

    const reqOptions = {
      domain: $stateParams.domain,
      id: $stateParams.filterId
    };

    vm.$onInit = () => {
      loadFilter();
    };

    function loadFilter() {
      function whenError(error) {
        vm.errorLoadingData = true;
        vm.errorText = error && error.status === 404 ?
          tFilter('AUTOMATION.DECISION_RULES.ERROR.NO_RULE_MATCHED', {filterId: $stateParams.filterId}, 'messageformat')
          : tFilter('AUTOMATION.DECISION_RULES.ERROR.LOADING_ERROR');
      }

      ApiFilters
        .show(reqOptions)
        .then(function(results) {
          vm.filter = results;
          vm.errorLoadingData = false;
        }).catch(whenError);
    }

    vm.reload = function() {
      loadFilter();
    };

    function save() {
      function success() {
        Notifier.display(tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.SUCCESS_SAVE_TEXT', {filterName: vm.filter.name}, 'messageformat'), {
          type: 'success', ttl: 1800
        });

        $state.go('app.assignment.automation.filterView', {
          domain: vm.domain,
          filterId: $stateParams.filterId,
          from: vm.filter.lastModifiedTime
              && vm.minDate
              && set(max([new Date(vm.filter.lastModifiedTime), vm.minDate]), ZERO_SECONDS).getTime(),
        });
      }

      function whenError(err) {
        if (err.status === 401) {
          return;
        }
        Notifier.display(tFilter('AUTOMATION.DECISION_RULES.ERROR.SAVE_ERROR'), {type: 'failure', ttl: 6000}, err);
        console.error(err);
      }

      ApiFilters.edit(reqOptions, vm.filter).then(function() {
        success();
      }).catch(whenError);
    }
  }
})();
