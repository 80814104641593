"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyTime = exports.mustKeepTime = exports.isSameDay = exports.isRangeValid = exports.isNot4DigitYear = exports.parseTDDateWithLocale = exports.parseTDDate = exports.FORMAT_AMPM_TD = exports.FORMAT_24H_TD = void 0;
var date_fns_1 = require("date-fns");
var date_1 = require("@utils/date/date");
var importLocale_1 = require("@components/DatePicker/importLocale");
exports.FORMAT_24H_TD = 'dd MMM yyyy HH:mm';
exports.FORMAT_AMPM_TD = 'dd MMM yyyy hh:mm T';
var parseTDDate = function (date) { return __awaiter(void 0, void 0, void 0, function () {
    var userLocale;
    return __generator(this, function (_a) {
        userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
        return [2 /*return*/, (0, exports.parseTDDateWithLocale)(date, userLocale)];
    });
}); };
exports.parseTDDate = parseTDDate;
var parseTDDateWithLocale = function (date, userLocale) { return __awaiter(void 0, void 0, void 0, function () {
    var localeToSet;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(0, date_1.localeUses24HourTime)()) return [3 /*break*/, 2];
                return [4 /*yield*/, (0, importLocale_1.importLocale)(userLocale)];
            case 1:
                localeToSet = _a.sent();
                return [2 /*return*/, (0, date_fns_1.parse)(date, exports.FORMAT_24H_TD, new Date(), { locale: localeToSet.default })];
            case 2: return [2 /*return*/, new Date(date)];
        }
    });
}); };
exports.parseTDDateWithLocale = parseTDDateWithLocale;
// When the user remove 2 digits from the year, the date is not valid anymore.
// but isValid() still return true.
// So we need to check if the year is 2 digits and if it is, we return false.
var isNot4DigitYear = function (dateStr) {
    var dateElms = dateStr.split(' ');
    if (dateElms.length > 3) {
        if (dateElms[2].length < 4) {
            return true;
        }
    }
    return false;
};
exports.isNot4DigitYear = isNot4DigitYear;
var isRangeValid = function (date, minValue, maxValue) {
    var dateToCompareMin = (0, date_fns_1.set)(date, date_1.ZERO_SECONDS);
    var dateToCompareMax = (0, date_fns_1.set)(date, date_1.ZERO_SECONDS);
    var minValueToCompare = minValue && (0, date_fns_1.set)(minValue, date_1.ZERO_SECONDS);
    var maxValueToCompare = maxValue && (0, date_fns_1.set)(maxValue, date_1.FIFTY_NINE_SECONDS);
    if (minValueToCompare && (0, date_fns_1.isBefore)(dateToCompareMin, minValueToCompare) && !(0, date_fns_1.isEqual)(dateToCompareMin, minValueToCompare)) {
        return false;
    }
    if (maxValueToCompare && (0, date_fns_1.isAfter)(dateToCompareMax, maxValueToCompare) && !(0, date_fns_1.isEqual)(dateToCompareMax, maxValueToCompare)) {
        return false;
    }
    return true;
};
exports.isRangeValid = isRangeValid;
var isSameDay = function (date, oldDate) {
    return (date.getDay() === oldDate.getDay() && date.getMonth() === oldDate.getMonth() && date.getFullYear() === oldDate.getFullYear());
};
exports.isSameDay = isSameDay;
/*
 By default, tempusDominus put the hour at current time when the user select a date.
 we want to keep the time of the new hour only if only the time change
 */
var mustKeepTime = function (date, oldDate) {
    var ONE_MIN = 60 * 1000;
    var ONE_HOUR = 60 * ONE_MIN;
    if ((0, exports.isSameDay)(date, oldDate)) {
        return true;
    }
    if (date.getTime() - oldDate.getTime() === ONE_HOUR || date.getTime() - oldDate.getTime() === ONE_MIN) {
        return true;
    }
    return false;
};
exports.mustKeepTime = mustKeepTime;
var copyTime = function (date, timeToCopy) {
    var newDate = new Date(date);
    newDate.setHours(timeToCopy.getHours());
    newDate.setMinutes(timeToCopy.getMinutes());
    newDate.setSeconds(timeToCopy.getSeconds());
    newDate.setMilliseconds(timeToCopy.getMilliseconds());
    return newDate;
};
exports.copyTime = copyTime;
