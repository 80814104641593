(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);


  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.phashImageListsEdit', {
      url: '/phash-image-lists/:name/edit',
      data: {
        title: 'PAGE_TITLE.EDIT_LIST'
      },
      views: {
        content: {
          template: require('./phashImageListsEdit.html'),
          controller: 'AdminPhashImagesEditCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
