"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionSummary = void 0;
var react_1 = __importStar(require("react"));
var usePageFocus_1 = require("@common/hooks/usePageFocus");
var useIntersectionObserver_1 = require("@common/hooks/useIntersectionObserver");
var getDecisionSummary_1 = require("@features/ItemCommon/components/DecisionSummary/util/getDecisionSummary");
var react_i18next_1 = require("react-i18next");
var SECOND = 1000;
var MINUTE = SECOND * 60;
var getIntervalTime = function (timeText) {
    if (timeText.includes('s') || timeText.includes('m')) {
        return SECOND;
    }
    return MINUTE;
};
function DecisionSummary(_a) {
    var job = _a.job, hideStatus = _a.hideStatus, hideBar = _a.hideBar;
    var isTabIdle = (0, usePageFocus_1.usePageFocus)();
    var t = (0, react_i18next_1.useTranslation)().t;
    var decisionSummaryRef = react_1.default.useRef(null);
    var intersectionObserverEntry = (0, useIntersectionObserver_1.useIntersectionObserver)(decisionSummaryRef, {}, true);
    var isIntersecting = (intersectionObserverEntry || {}).isIntersecting;
    var _b = __read(react_1.default.useState(''), 2), decisionFromNowDynamic = _b[0], setDecisionFromNowDynamic = _b[1];
    var _c = __read(react_1.default.useState(''), 2), timeWaitingDynamic = _c[0], setTimeWaitingDynamic = _c[1];
    var feedbacks = (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d, _e;
        var history = ((_a = job === null || job === void 0 ? void 0 : job.ad) === null || _a === void 0 ? void 0 : _a.history) && Array.isArray((_b = job === null || job === void 0 ? void 0 : job.ad) === null || _b === void 0 ? void 0 : _b.history) && ((_c = job === null || job === void 0 ? void 0 : job.ad) === null || _c === void 0 ? void 0 : _c.history.length) > 0 && ((_d = job === null || job === void 0 ? void 0 : job.ad) === null || _d === void 0 ? void 0 : _d.history[0]);
        return history && ((_e = history === null || history === void 0 ? void 0 : history.meta) === null || _e === void 0 ? void 0 : _e.feedback);
    }, [job]);
    var data = (0, react_1.useMemo)(function () {
        return (0, getDecisionSummary_1.getDecisionSummary)(job);
    }, [job]);
    if (!data)
        return null;
    var status = data.status, decisionFromNow = data.decisionFromNow, decisionFromNowTooltip = data.decisionFromNowTooltip, getDecisionFromNow = data.getDecisionFromNow, getDecisionFromNowLabel = data.getDecisionFromNowLabel, timeWaiting = data.timeWaiting, getTimeWaitingLabel = data.getTimeWaitingLabel, timeWaitingTooltip = data.timeWaitingTooltip, timeToDecision = data.timeToDecision, statusActorText = data.statusActorText, iconName = data.iconName, timestamp = data.timestamp, getTimeSinceEntered = data.getTimeSinceEntered, hasDecision = data.hasDecision;
    (0, react_1.useEffect)(function () {
        var intervalDecision;
        var intervalTimeWaiting;
        if (isIntersecting) {
            var intervalTime = getIntervalTime(decisionFromNow || timeWaiting);
            intervalDecision = setInterval(function () {
                var _a = getDecisionFromNow(timestamp), timeNum = _a.timeNum, timeUnit = _a.timeUnit;
                setDecisionFromNowDynamic(getDecisionFromNowLabel(timeNum, timeUnit));
            }, intervalTime);
            if (!hasDecision) {
                intervalTimeWaiting = setInterval(function () {
                    var timeSinceEntered = getTimeSinceEntered();
                    setTimeWaitingDynamic(getTimeWaitingLabel(status, timeSinceEntered));
                }, intervalTime);
            }
        }
        return function () {
            clearInterval(intervalDecision);
            clearInterval(intervalTimeWaiting);
        };
    }, [isIntersecting, isTabIdle]);
    return (react_1.default.createElement("div", { className: "job-summary ".concat(data.status.replace(' ', '_')), ref: decisionSummaryRef },
        !hideBar && react_1.default.createElement("hr", null),
        react_1.default.createElement("div", { className: "job-summary-decision", "data-cy": "job-summary-decision" },
            react_1.default.createElement("div", null,
                !hideStatus && (react_1.default.createElement("span", { className: "job-summary-action" },
                    react_1.default.createElement("span", { className: "job-summary-action__icon implioicons-".concat(iconName) }),
                    statusActorText)),
                (decisionFromNowDynamic || decisionFromNow) && hasDecision && (react_1.default.createElement("div", { className: "job-summary-timestamp eva-tooltip-container" },
                    react_1.default.createElement("span", { className: "implioicons-calendar decision-summary-icon" }),
                    decisionFromNowDynamic || decisionFromNow,
                    decisionFromNowTooltip && react_1.default.createElement("span", { className: "eva-tooltip" }, decisionFromNowTooltip)))),
            (timeToDecision || timeWaitingDynamic || timeWaiting) && (react_1.default.createElement("div", { className: "job-summary-time-wrapper" },
                timeToDecision && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("span", { className: "implioicons-clock decision-summary-icon" }),
                    react_1.default.createElement("span", { className: "job-summary-time" }, timeToDecision))),
                (timeWaitingDynamic || timeWaiting) && !hasDecision && (react_1.default.createElement("span", { className: "job-summary-time eva-tooltip-container" },
                    react_1.default.createElement("span", { className: "implioicons-clock decision-summary-icon" }),
                    timeWaitingDynamic || timeWaiting,
                    timeWaitingTooltip && react_1.default.createElement("span", { className: "eva-tooltip" }, timeWaitingTooltip)))))),
        react_1.default.createElement("div", { className: "job-summary-feedback" }, feedbacks && feedbacks.length > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", null, t('ITEM.FEEDBACK.LABEL')),
            feedbacks.map(function (item, index) {
                return (react_1.default.createElement("div", { className: "job-summary-feedback-item", key: index }, item.name));
            }))))));
}
exports.DecisionSummary = DecisionSummary;
