"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetDictionariesQuery = exports.dictionariesApi = void 0;
var baseApi_1 = require("./baseApi");
// // Define a service using a base URL and expected endpoints
exports.dictionariesApi = baseApi_1.baseApi.injectEndpoints({
    endpoints: function (builder) { return ({
        getDictionaries: builder.query({
            query: function (_a) {
                var domain = _a.domain, kind = _a.kind;
                return ({
                    url: "/sites/".concat(domain, "/filters/dictionaries?kind=").concat(kind),
                    method: 'GET',
                });
            },
        }),
    }); },
});
exports.useGetDictionariesQuery = exports.dictionariesApi.useGetDictionariesQuery;
