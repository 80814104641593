"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRefreshInterval = void 0;
var date_1 = require("@utils/date/date");
var getRefreshInterval = function (createdAtDate, publishedAtDate, updatedAtDate) {
    // For each date, we need to calculate the interval from now
    var timeUnitCreated = (createdAtDate ? (0, date_1.getIntervalFromNow)(new Date(createdAtDate)) : { timeUnit: null }).timeUnit;
    var timeUnitPublished = (publishedAtDate ? (0, date_1.getIntervalFromNow)(new Date(publishedAtDate)) : { timeUnit: null }).timeUnit;
    var timeUnitUpdated = (updatedAtDate ? (0, date_1.getIntervalFromNow)(new Date(updatedAtDate)) : { timeUnit: null }).timeUnit;
    // Set the refresh interval based on the time unit
    // By default, we refresh every hour
    var refreshInterval = 1000 * 60 * 60;
    // If one of the dates is in seconds, we refresh every second
    if (timeUnitCreated === 'second' || timeUnitPublished === 'second' || timeUnitUpdated === 'second') {
        refreshInterval = 1000;
        // If one of the dates is in minutes, we refresh every minute
    }
    else if (timeUnitCreated === 'minute' || timeUnitPublished === 'minute' || timeUnitUpdated === 'minute') {
        refreshInterval = 1000 * 60;
    }
    return refreshInterval;
};
exports.getRefreshInterval = getRefreshInterval;
