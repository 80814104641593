"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateJobGroup = exports.updateDecisionGroup = exports.removeModerationNoteGroup = exports.updateModerationNotesGroup = exports.updateFeedbackGroup = exports.updateItemCustomerSpecificGroup = exports.updateItemCurrentGroup = void 0;
var updateItemCurrentGroup = function (itemsGroup, item, newCurrent) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id)) {
            newJobGroup.jobs[0].ad.current = newCurrent;
        }
        return newJobGroup;
    });
};
exports.updateItemCurrentGroup = updateItemCurrentGroup;
var updateItemCustomerSpecificGroup = function (itemsGroup, item, newCustomerSpecific) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id)) {
            newJobGroup.jobs[0].ad.customerSpecific = newCustomerSpecific;
        }
        return newJobGroup;
    });
};
exports.updateItemCustomerSpecificGroup = updateItemCustomerSpecificGroup;
var updateFeedbackGroup = function (itemsGroup, item, feedback) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id)) {
            newJobGroup.jobs[0].selectedFeedbackIds = feedback.map(function (item) { return item.feedbackId; });
            newJobGroup.jobs[0].selectedFeedback = feedback.map(function (_a) {
                var id = _a.id;
                return ({ id: id });
            });
        }
        return newJobGroup;
    });
};
exports.updateFeedbackGroup = updateFeedbackGroup;
var updateModerationNotesGroup = function (itemsGroup, item, notes) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id) && newJobGroup.jobs[0].moderationNotes) {
            newJobGroup.jobs[0].moderationNotes.notes = notes;
        }
        return newJobGroup;
    });
};
exports.updateModerationNotesGroup = updateModerationNotesGroup;
var removeModerationNoteGroup = function (itemsGroup, item, noteId) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id) && newJobGroup.jobs[0].moderationNotes) {
            newJobGroup.jobs[0].moderationNotes.notes = newJobGroup.jobs[0].moderationNotes.notes.filter(function (note) { return note.id !== noteId; });
        }
        return newJobGroup;
    });
};
exports.removeModerationNoteGroup = removeModerationNoteGroup;
var updateDecisionGroup = function (itemsGroup, item, decision) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id)) {
            newJobGroup.jobs[0].selectedDecision = decision;
            if (decision.queue) {
                newJobGroup.jobs[0].routingQueueObj = decision.queue;
            }
        }
        return newJobGroup;
    });
};
exports.updateDecisionGroup = updateDecisionGroup;
var updateJobGroup = function (itemsGroup, item) {
    return itemsGroup === null || itemsGroup === void 0 ? void 0 : itemsGroup.map(function (jobGroup) {
        var _a, _b;
        var newJobGroup = __assign({}, jobGroup);
        if (((_a = newJobGroup.jobs[0]) === null || _a === void 0 ? void 0 : _a.ad) && newJobGroup.jobs[0].ad.id === ((_b = item.ad) === null || _b === void 0 ? void 0 : _b.id)) {
            newJobGroup.jobs[0] = item;
        }
        return newJobGroup;
    });
};
exports.updateJobGroup = updateJobGroup;
