"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditableField = void 0;
var react_1 = require("react");
var useEditableField = function (value, isNotEditable, setValue, textAreaRef) {
    var _a = __read((0, react_1.useState)('VIEW'), 2), mode = _a[0], setMode = _a[1];
    var _b = __read((0, react_1.useState)(value), 2), confirmedValue = _b[0], setConfirmedValue = _b[1];
    var _c = __read((0, react_1.useState)(String(value)), 2), tempValue = _c[0], setTempValue = _c[1];
    (0, react_1.useEffect)(function () {
        var handleKeyDown = function (event) {
            if (event.key === 'Escape') {
                resetToOriginalValue();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    var handleClick = function () {
        if (isNotEditable) {
            return;
        }
        handleSetMode('EDIT');
    };
    // only for text area : detect when edit mode is entered to adjust height
    (0, react_1.useEffect)(function () {
        if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
            var valueLength = textAreaRef.current.value.length;
            textAreaRef.current.setSelectionRange(valueLength, valueLength);
        }
    }, [mode]);
    var onClickOutside = function () {
        if (tempValue !== confirmedValue) {
            confirmChanges(tempValue);
        }
        handleSetMode('VIEW');
    };
    var handleKeyDown = function (event) {
        if (event.key === 'Enter' && event.shiftKey) {
            onClickOutside();
            event.stopPropagation();
            event.preventDefault();
        }
    };
    var resetToOriginalValue = function () {
        setTempValue(String(confirmedValue));
        handleSetMode('VIEW');
    };
    var confirmChanges = function (value) {
        if (!setValue) {
            return null;
        }
        setValue(value);
        setConfirmedValue(value);
        handleSetMode('VIEW');
    };
    var handleSetMode = function (mode) {
        if (!setMode) {
            return null;
        }
        setMode(mode);
    };
    (0, react_1.useEffect)(function () {
        handleSetMode(mode || 'VIEW');
        setConfirmedValue(value);
        setTempValue(String(confirmedValue));
    }, [mode, value]);
    return {
        mode: mode,
        handleClick: handleClick,
        onClickOutside: onClickOutside,
        handleKeyDown: handleKeyDown,
        resetToOriginalValue: resetToOriginalValue,
        confirmChanges: confirmChanges,
        tempValue: tempValue,
        setTempValue: setTempValue,
        setMode: setMode,
    };
};
exports.useEditableField = useEditableField;
