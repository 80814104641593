"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Tooltip_1 = require("@common/components/Tooltip");
var duration_1 = require("@utils/date/duration");
var date_1 = require("@utils/date/date");
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var ItemStatusAndTooltip_1 = require("@features/ItemLogPage/components/OverallList/ItemStatusAndTooltip");
var useIntersectionObserver_1 = require("@/react-app/common/hooks/useIntersectionObserver");
var SearchPageItemStatus_1 = require("./SearchPageItemStatus");
function SearchItemTimestamp(_a) {
    var _b;
    var job = _a.job, status = _a.status, lastHistoryTimestamp = _a.lastHistoryTimestamp, timeEntered = _a.timeEntered, actor = _a.actor, angularJsDependencies = _a.angularJsDependencies;
    var t = (0, react_i18next_1.useTranslation)().t;
    var adHasDecision = job.isDecisionMade;
    var _c = (0, duration_1.getJobDuration)(lastHistoryTimestamp), timeUnit = _c.timeUnit, timeValue = _c.timeValue;
    var _d = __read((0, react_1.useState)(timeValue), 2), timeAgoValue = _d[0], setTimeAgoValue = _d[1];
    var _e = __read((0, react_1.useState)(timeUnit), 2), timeAgoUnit = _e[0], setTimeAgoUnit = _e[1];
    var _f = __read((0, react_1.useState)(), 2), intervalId = _f[0], setIntervalId = _f[1];
    var ref = (0, react_1.useRef)(null);
    var intersectionObserverEntry = (0, useIntersectionObserver_1.useIntersectionObserver)(ref, 
    // to be able to test the intersection observer, we need to set the rootMargin to -100px (to see two items in the viewport but only one counter is running)
    // rootMargin: '10px' to start running the counter when the item is 10px before the viewport
    { rootMargin: '10px' }, true);
    var isIntersecting = (intersectionObserverEntry || {}).isIntersecting;
    var _g = (0, ItemStatusAndTooltip_1.getJobStatusTooltip)(status, actor), timeZone = _g.timeZone, by = _g.by, timePrep = _g.timePrep;
    var translationKey = (0, ItemStatusAndTooltip_1.getStatusTranslation)(status, actor.domain);
    var timeEnteredFormatted = (0, date_1.formatDate)(new Date(timeEntered));
    var statusTranslation = t(translationKey);
    var updateTimeAgo = function () {
        var _a = (0, duration_1.getJobDuration)(lastHistoryTimestamp), timeUnit = _a.timeUnit, timeValue = _a.timeValue;
        setTimeAgoUnit(timeUnit);
        setTimeAgoValue(timeValue);
    };
    var clearAndStart = function () {
        clearInterval(intervalId);
        updateTimeAgo();
        var interval = setInterval(function () { return updateTimeAgo(); }, 1000);
        setIntervalId(interval);
    };
    // we want to run the counter only for items visible in the viewport
    (0, react_1.useEffect)(function () {
        if (!isIntersecting) {
            clearInterval(intervalId);
            return;
        }
        clearAndStart();
    }, [isIntersecting, (_b = job === null || job === void 0 ? void 0 : job.ad) === null || _b === void 0 ? void 0 : _b.id, lastHistoryTimestamp]);
    (0, react_1.useEffect)(function () {
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    var getSearchStatus = function (status) {
        var mapping = {
            reviewable: 'MANUAL',
            approved: 'APPROVE',
            refused: 'REFUSE',
            'no decision': '"NO DECISION"',
            deferred: 'DEFER',
            new: 'new',
        };
        if (status === 'processing' || status === 'escalated') {
            return status;
        }
        return mapping[status];
    };
    var getField = function (status, actorDomain) {
        var field = 'metadata.result';
        if (['approved', 'refused'].includes(status)) {
            if (actorDomain === 'processor') {
                field = 'metadata.automaticResults.result';
            }
            if (actorDomain === 'agent') {
                field = 'metadata.manualResults.result';
            }
            if (actorDomain === 'client') {
                field = 'metadata.clientResults.result';
            }
        }
        return field;
    };
    return (react_1.default.createElement("div", { className: "search-item-timestamp-container" },
        react_1.default.createElement("a", { className: "eva-search-list-view-status flex-item-status eva-tooltip-container--left custom-item-status", href: angularJsDependencies.href('app.assignment.search', {
                filters: encodeURIComponent("".concat(getField(status, actor.domain), ":").concat(getSearchStatus(status))),
                cursor: null,
            }) },
            react_1.default.createElement(SearchPageItemStatus_1.SearchPageItemStatus, { job: job })),
        adHasDecision && (react_1.default.createElement("span", { className: "eva-search-list-view-time-ago eva-tooltip-container--left", ref: ref },
            react_1.default.createElement(react_1.default.Fragment, null,
                timeAgoValue > 1 && react_1.default.createElement(react_i18next_1.Trans, { i18nKey: "SEARCH.ITEM.TIME_AGO_REACT_PLURAL", values: { timeAgoValue: timeAgoValue, timeAgoUnit: timeAgoUnit } }),
                timeAgoValue === 1 && react_1.default.createElement(react_i18next_1.Trans, { i18nKey: "SEARCH.ITEM.TIME_AGO_REACT", values: { timeAgoValue: timeAgoValue, timeAgoUnit: timeAgoUnit } })),
            react_1.default.createElement(Tooltip_1.Tooltip, null, "".concat(statusTranslation, " ").concat(by, " ").concat(timePrep, " ").concat((0, date_1.formatDate)(new Date(lastHistoryTimestamp)), " ").concat(timeZone)))),
        react_1.default.createElement("span", { className: "eva-search-list-view-time-entered eva-tooltip-container--left" },
            timeEnteredFormatted,
            react_1.default.createElement("span", { className: "eva-tooltip" },
                react_1.default.createElement(react_i18next_1.Trans, { i18nKey: "SEARCH.ITEM.RECEIVED_AT_TOOLTIP_REACT", values: {
                        TIMESTAMP: timeEnteredFormatted,
                        TIMEZONE: timeZone,
                    } })))));
}
exports.default = SearchItemTimestamp;
