"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToWithCallback = void 0;
// scroll to with callback function
var scrollToWithCallback = function (top, behavior, callback) {
    var fixedOffset = top.toFixed();
    var onScroll = function () {
        if (window.pageYOffset.toFixed() === fixedOffset) {
            window.removeEventListener('scroll', onScroll);
            callback();
        }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({
        top: top,
        behavior: behavior,
    });
};
exports.scrollToWithCallback = scrollToWithCallback;
