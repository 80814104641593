"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeedbackIdsForJob = exports.getCurrentQueueName = void 0;
function getCurrentQueueName(queues, queueId) {
    var currentQueue = queues.find(function (queue) { return queue.id === queueId; });
    if (currentQueue) {
        return currentQueue.label;
    }
    return '';
}
exports.getCurrentQueueName = getCurrentQueueName;
function getFeedbackIdsForJob(job, feedbackSetArray) {
    var isValidFeedbackIds = Array.isArray(job.selectedFeedbackIds) && job.selectedFeedbackIds.length > 0;
    var isValidFeedbackArray = Array.isArray(feedbackSetArray) && feedbackSetArray.length > 0;
    if (isValidFeedbackIds && isValidFeedbackArray) {
        return feedbackSetArray
            .filter(function (feedback) { return ((job === null || job === void 0 ? void 0 : job.selectedFeedbackIds) || []).includes(feedback.feedbackId); })
            .map(function (_a) {
            var id = _a.id;
            return ({ id: id });
        });
    }
}
exports.getFeedbackIdsForJob = getFeedbackIdsForJob;
