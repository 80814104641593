"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubmitDecisionApiData = exports.parseSelectedFeedback = void 0;
var getCurrentQueueNameAndId_1 = require("./getCurrentQueueNameAndId");
var parseSelectedFeedback = function (job) {
    var _a, _b;
    return (_b = (_a = job.selectedFeedback) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({ id: item.id }); })) !== null && _b !== void 0 ? _b : [];
};
exports.parseSelectedFeedback = parseSelectedFeedback;
var mapMediaMetadata = function (mediaArray, mediaType) {
    var _a;
    return ((_a = mediaArray === null || mediaArray === void 0 ? void 0 : mediaArray.map(function (media) {
        var _a;
        var baseMedia = {
            id: media.id,
            src: media.src,
            deleted: (_a = media.deleted) !== null && _a !== void 0 ? _a : false,
        };
        if (mediaType === 'image') {
            return __assign(__assign({}, baseMedia), { transformations: media.transformations });
        }
        return baseMedia;
    })) !== null && _a !== void 0 ? _a : []);
};
var getSubmitDecisionApiData = function (job) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var decisionType = (_b = (_a = job === null || job === void 0 ? void 0 : job.selectedDecision) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    var adForApi = {
        current: {
            content: __assign(__assign({}, (_c = job.ad) === null || _c === void 0 ? void 0 : _c.current.content), { 
                // Omit(Remove) 'imageServiceUrl', 'augBoxes', 'originalSize', 'phash' from job.imagesMetadataArray
                images: job.imagesMetadataArray && mapMediaMetadata(job.imagesMetadataArray, 'image'), videos: job.videosMetadataArray && mapMediaMetadata(job.videosMetadataArray, 'video') }),
            user: (_d = job.ad) === null || _d === void 0 ? void 0 : _d.current.user,
        },
        customerSpecific: (_e = job.ad) === null || _e === void 0 ? void 0 : _e.customerSpecific,
    };
    var feedbackData = (0, exports.parseSelectedFeedback)(job);
    var refusalReasons = ((_f = job.selectedDecision) === null || _f === void 0 ? void 0 : _f.reason) ? [job.selectedDecision.reason] : null;
    var mostRecentHistory = (_h = (_g = job.ad) === null || _g === void 0 ? void 0 : _g.history) === null || _h === void 0 ? void 0 : _h[0];
    var _r = __read(mostRecentHistory
        ? (0, getCurrentQueueNameAndId_1.getCurrentQueueNameAndId)(mostRecentHistory)
        : [undefined, undefined], 2), currentQueueName = _r[0], currentQueueId = _r[1];
    var status = (_m = (_l = (_k = (_j = job.ad) === null || _j === void 0 ? void 0 : _j.current) === null || _k === void 0 ? void 0 : _k.meta) === null || _l === void 0 ? void 0 : _l.status) !== null && _m !== void 0 ? _m : '';
    var isReviewableOrDeferred = ['reviewable', 'deferred'].includes(status);
    var isDecisionDefer = decisionType === 'DEFER';
    var decisionData = {
        id: (_o = job.ad) === null || _o === void 0 ? void 0 : _o.id,
        decision: decisionType,
        ad: adForApi,
        feedback: feedbackData,
        decisions: refusalReasons,
        queueName: isReviewableOrDeferred ? currentQueueName : undefined,
        queueId: isReviewableOrDeferred ? currentQueueId : undefined,
        routingQueue: isDecisionDefer ? (_p = job.routingQueueObj) === null || _p === void 0 ? void 0 : _p.id : undefined,
        routingQueueName: isDecisionDefer ? (_q = job.routingQueueObj) === null || _q === void 0 ? void 0 : _q.label : undefined,
    };
    return decisionData;
};
exports.getSubmitDecisionApiData = getSubmitDecisionApiData;
