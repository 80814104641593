import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('JobGroupManager', JobGroupManager);

  function JobGroupManager(getDecisionData, ajax) {
    'ngInject';

    /**
     * function to filter all the job groups that have a decision
     * @param jobGroups {array}
     * @returns {array}
     */
    function filterJobGroupsWithDecisions(jobGroups) {
      return jobGroups.filter((jobGroup) =>
        !!jobGroup && !!jobGroup.jobs && !!jobGroup.jobs[0] &&
      !!jobGroup.jobs[0].selectedDecision && !!jobGroup.jobs[0].selectedDecision.type);
    }

    /**
     * Set decision to older jobs
     * @param jobGroup
     */
    function setDecisionToOlderJobs(jobGroup) {
      const latestJob = jobGroup.jobs[0];
      const olderJobDecisionType = latestJob.selectedDecision.type === 'defer' ? 'defer' : 'no decision';
      const olderJob = jobGroup.jobs.slice(1);
      olderJob.forEach((job) => {
        if (olderJobDecisionType === 'defer' && latestJob.routingQueueObj) {
          job.routingQueueObj = latestJob.routingQueueObj;
        }
        job.selectedDecision = {
          type: olderJobDecisionType
        };
      });
      return jobGroup;
    }

    function submitDecision(domain, jobGroups, queueName, queueId) {

      const allJobs = filterJobGroupsWithDecisions(jobGroups)
        .map((jobGroup) => setDecisionToOlderJobs(jobGroup))
        .reduce((acc, jobGroup)=> acc.concat(jobGroup.jobs), []);

      const data = allJobs.map((job) => getDecisionData(job));
      const decisions = data.map((decision) => ({...decision, queueName, queueId}));
  
      return ajax.put(`/ads/${domain}/decisions`, {decisions});
    }

    function setValueToAllJobs(jobGroups, obj) {
      _.forEach(jobGroups, (jobGroup)=> {
        _.forEach(jobGroup.jobs, (job)=> {
          _.assign(job, obj);
        });
      });
    }

    function getLatestJobs(jobGroups) {
      return _.map((jobGroups), (jobGroup)=> jobGroup.jobs[0]);
    }

    return {
      submitDecision,
      setValueToAllJobs,
      getLatestJobs
    };
  }
})();
