'use strict';

const { redirectToAuth0Form } = require('@/react-app/common/utils/handleAuth0redirect');

angular.module('eva2-angular')
  .factory('ServerSideValidationHTTPInterceptor', function($q, $rootScope, $log, $location, $injector, Notifier, tFilter) {
    return {
      responseError: function(response) {
        if (!response || !response.config) {
          return $q.reject(response);
        }

        if (response.config.method === 'POST' && response.data && response.data.context) {
          const eventName = ['ServerSideValidationFailed', response.data.context].join(':');
          $rootScope.$broadcast(eventName, response.data);
        }

        const $state = $injector.get('$state');
        const CurrentUser = $injector.get('CurrentUser');
        if (response.status === 401) {
          CurrentUser.logOut('You need to login to access this page');
          if($state.current.name !== '') {
            Notifier.display(tFilter('LOG_IN.ERROR.SESSION_TOKEN_EXPIRED'), {type: 'error', ttl: 8000});
          }
          // To make sure redirectToAuth0Form is called only inside the app and prevent multiple calls
          if($state.current.name){
            redirectToAuth0Form($state);
          }
        }
        window.sentryService.captureException(new Error(`API Error - Status: ${response.status}, Method: ${response.config?.method}, Url: ${response.config?.url}  Data: ${JSON.stringify(response.data)}`));
        return $q.reject(response);
      }
    };
  });
