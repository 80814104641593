"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemLogDatePicker = exports.getDefaultValues = void 0;
var DateRangePicker_1 = require("@common/components/DatePicker/DateRangePicker");
var date_1 = require("@utils/date/date");
var date_fns_1 = require("date-fns");
var react_1 = __importStar(require("react"));
var getDefaultValues = function (searchParam) {
    var searchParams = new URLSearchParams(searchParam);
    var fromParam = searchParams.get('from');
    var toParam = searchParams.get('to');
    var fromValue = fromParam ? new Date(parseInt(fromParam)) : undefined;
    var toValue = toParam ? (0, date_fns_1.set)(new Date(parseInt(toParam)), { milliseconds: 999 }) : undefined;
    return [fromValue, toValue];
};
exports.getDefaultValues = getDefaultValues;
var souldUpdate = function (newTo, newForm, toValue, fromValue) {
    if (newTo && toValue && !(0, date_fns_1.isEqual)(newTo, toValue)) {
        return true;
    }
    if (newForm && fromValue && !(0, date_fns_1.isEqual)(newForm, fromValue)) {
        return true;
    }
    if ((!toValue && newTo) || (toValue && !newTo)) {
        return true;
    }
    if ((!fromValue && newForm) || (fromValue && !newForm)) {
        return true;
    }
    // From must be before to
    if (newForm &&
        newTo &&
        fromValue &&
        toValue &&
        !(0, date_fns_1.isEqual)(newForm, fromValue) &&
        !(0, date_fns_1.isEqual)(newTo, toValue) &&
        (0, date_fns_1.isAfter)(newTo, newForm)) {
        return true;
    }
    return false;
};
exports.ItemLogDatePicker = react_1.default.memo(function (props) {
    var onChangeCb = props.onChangeCb, defaultStartDate = props.defaultStartDate, shouldReload = props.shouldReload, setShouldReload = props.setShouldReload, minDate = props.minDate;
    var _a = __read((0, react_1.useState)(undefined), 2), overviewFromDate = _a[0], setOverviewFromDate = _a[1];
    var _b = __read((0, react_1.useState)(undefined), 2), overviewToDate = _b[0], setOverviewToDate = _b[1];
    var defaultToValue = (0, date_fns_1.set)(new Date(), __assign({ hours: 23, minutes: 59 }, date_1.FIFTY_NINE_SECONDS));
    (0, react_1.useEffect)(function () {
        // First date init from url params
        if (shouldReload === undefined) {
            var _a = __read((0, exports.getDefaultValues)(window.location.search), 2), urlFromValue = _a[0], urlToValue = _a[1];
            if (urlFromValue) {
                setOverviewFromDate((0, date_fns_1.set)((0, date_fns_1.max)([urlFromValue, minDate]), date_1.ZERO_SECONDS));
            }
            else if (defaultStartDate && minDate) {
                setOverviewFromDate((0, date_fns_1.set)((0, date_fns_1.max)([defaultStartDate, minDate]), date_1.ZERO_SECONDS));
            }
            else if (minDate) {
                setOverviewFromDate(minDate);
            }
            urlToValue && setOverviewToDate(urlToValue);
            (urlFromValue || urlToValue) && onChangeCb && onChangeCb(urlFromValue, urlToValue);
        }
        else if (shouldReload === true) {
            // setOverviewFromDate(undefined);
            setOverviewToDate(undefined);
        }
        setShouldReload(false);
    }, [shouldReload]);
    var changeRange = function (fromDate, toDate) {
        var _a = __read((0, exports.getDefaultValues)(window.location.search), 2), urlFromValue = _a[0], urlToValue = _a[1];
        if (souldUpdate(toDate, fromDate, urlToValue, urlFromValue)) {
            onChangeCb && onChangeCb(fromDate, toDate);
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null, overviewFromDate && (react_1.default.createElement(DateRangePicker_1.DateRangePicker, { defaultFromValue: overviewFromDate, defaultToValue: overviewToDate, defaultMinValue: minDate, defaultMaxValue: defaultToValue, onChangeCb: changeRange, shouldReload: shouldReload, allowNull: true }))));
});
