"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultValuesWithDefault = exports.getDefaultValues = void 0;
// To persist the last selected date range, we look at the previous params in the url
// if there is no value in the url, we use the default value
var date_fns_1 = require("date-fns");
var date_1 = require("@utils/date/date");
var getDefaultValues = function (searchParam) {
    // this value is not being considered be cause we are setting the default value while navigating to the statistic in
    // src/app/partial/overview/overview.route.js from: moment().subtract(6, 'days').startOf('day').format(),
    var defaultFromValue = (0, date_fns_1.set)((0, date_fns_1.sub)(new Date(), { days: 6 }), __assign({ hours: 0, minutes: 0 }, date_1.ZERO_SECONDS));
    var defaultToValue = (0, date_fns_1.set)(new Date(), __assign({ hours: 23, minutes: 59 }, date_1.FIFTY_NINE_SECONDS));
    return (0, exports.getDefaultValuesWithDefault)(searchParam, defaultFromValue, defaultToValue);
};
exports.getDefaultValues = getDefaultValues;
var getDefaultValuesWithDefault = function (searchParam, defaultFromValue, defaultToValue) {
    var searchParams = new URLSearchParams(searchParam);
    var fromParam = searchParams.get('from');
    var toParam = searchParams.get('to');
    var fromValue = fromParam ? new Date(fromParam) : defaultFromValue;
    var toValue = toParam ? (0, date_fns_1.set)(new Date(toParam), { milliseconds: 999 }) : defaultToValue;
    return [fromValue, toValue];
};
exports.getDefaultValuesWithDefault = getDefaultValuesWithDefault;
