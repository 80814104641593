import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@store';
import React from 'react';
import ItemDetailPage from '@features/ItemDetailPage';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('EvaJobCtrl', EvaJobCtrl);

  function EvaJobCtrl(
    $stateParams,
    submitDecisionForOneJob,
    $timeout,
    EvaLogger, $state, CurrentUser, UserPermissionManager,$scope
  ) {
    'ngInject';

    const vm = this;
    const root = createRoot(document.querySelector('#item-detail'));
    const domain = $stateParams.domain;
    const itemId=$stateParams.id;
    vm.$onInit = () => {
      renderItemDetailPage();
    };

    $scope.$on('$destroy', function(){
      root.unmount();
    });

    const renderItemDetailPage = () => {
      $timeout(() => {
        root.render(
          <Provider store={store}>
            <ItemDetailPage
              itemId={itemId}
              domain={domain}
              angularJsDependencies={{ EvaLogger, $state, CurrentUser, UserPermissionManager,href: $state.href}}
            />
          </Provider>,
        );
      });
    };

  }
})();
