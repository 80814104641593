(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .config(appConfig);


  function appConfig($stateProvider) {
    $stateProvider.state('app.assignment.automation.phashImageListsCreate', {
      url: '/phash-image-lists/new',
      data: {
        title: 'PAGE_TITLE.CREATE_LIST'
      },
      views: {
        content: {
          template: require('./phashImageListsCreate.html'),
          controller: 'AdminPhashImagesCreateCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
