"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupJobsByRemoteId = exports.sortJobsInJobGroup = void 0;
function sortJobsInJobGroup(jobGroup) {
    // sort data in descending order
    var compare = function (job1, job2) { var _a, _b, _c, _d, _e, _f; return new Date(((_c = (_b = (_a = job2 === null || job2 === void 0 ? void 0 : job2.ad) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.timeEntered) || 0).getTime() - new Date(((_f = (_e = (_d = job1 === null || job1 === void 0 ? void 0 : job1.ad) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 : _f.timeEntered) || 0).getTime(); };
    jobGroup.jobs.sort(compare);
}
exports.sortJobsInJobGroup = sortJobsInJobGroup;
function groupJobsByRemoteId(jobs) {
    var jobGroupsMap = new Map();
    jobs.forEach(function (job) {
        var _a;
        if (!job.ad || !job.ad.remoteId) {
            throw new Error("Job does not have remote id ".concat(job));
        }
        var remoteId = job.ad.remoteId.id;
        if (!jobGroupsMap.has(remoteId)) {
            jobGroupsMap.set(remoteId, { remoteId: remoteId, jobs: [] });
        }
        (_a = jobGroupsMap.get(remoteId)) === null || _a === void 0 ? void 0 : _a.jobs.push(job);
    });
    var jobGroups = Array.from(jobGroupsMap.values());
    jobGroups.forEach(function (jobGroup) { return sortJobsInJobGroup(jobGroup); });
    return jobGroups;
}
exports.groupJobsByRemoteId = groupJobsByRemoteId;
